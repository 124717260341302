<template>
	<div style="background: #f3f2f7; border: 1px solid #cecece; border-radius: 0 8px 0 0" class="p-1">
		<b-overlay :show="isLoading">
			<!-- <b-button @click="massiveEvents.show = !massiveEvents.show">ttest</b-button> -->
			<b-row class="mb-2" v-if="!ownChild">
				<b-col class="d-flex align-items-center" cols="12" md="9">
					<b-tabs v-model="tab" @input="getRows">
						<b-tab :disabled="false">
							<template #title>
								Proximas
								<b-badge class="ml-1" variant="danger" v-if="G_TOURNAMENTS_COUNTERS.week && tab == 0">
									<!-- {{ counters.actual }} -->
									{{ G_TOURNAMENTS_COUNTERS.week }}
								</b-badge>
							</template>
						</b-tab>
						<!-- <b-tab :disabled="false">
							<template #title>
								Proximas
								<b-badge class="ml-1" variant="danger" v-if="counters.next">
									{{ counters.next }}
								</b-badge>
							</template>
						</b-tab> -->
						<b-tab :disabled="false">
							<template #title>
								Jugadas
								<b-badge class="ml-1" variant="danger" v-if="G_TOURNAMENTS_COUNTERS.played">
									{{ G_TOURNAMENTS_COUNTERS.played }}
								</b-badge>
							</template>
						</b-tab>
						<b-tab :disabled="false">
							<template #title>
								Canceladas
								<b-badge class="ml-1" variant="danger" v-if="G_TOURNAMENTS_COUNTERS.canceled">
									{{ G_TOURNAMENTS_COUNTERS.canceled }}
								</b-badge>
							</template>
						</b-tab>
					</b-tabs>
				</b-col>
				<b-col
					class="d-flex flex-column flex-xl-row align-items-xl-center justify-content-xl-end gap-x-2"
					cols="12"
					md="3"
				>
					<div class="d-flex align-items-center mt-md-1" v-if="tab == 0">
						<feather-icon
							icon="ChevronLeftIcon"
							size="40"
							class="text-primary cursor-pointer"
							style="margin-right: 10px"
							@click="previousWeek()"
						/>

						<b-form-datepicker
							class="border-primary"
							style="width: 140px"
							locale="en-US"
							placeholder="Desde"
							:disabled="true"
							:date-format-options="{
								month: 'numeric',
								day: 'numeric',
								year: 'numeric',
							}"
							v-model="weekFrom"
						/>
						<b-form-datepicker
							class="border-primary ml-1"
							locale="en-US"
							style="width: 140px"
							placeholder="Hasta"
							:disabled="true"
							:date-format-options="{
								month: 'numeric',
								day: 'numeric',
								year: 'numeric',
							}"
							v-model="weekTo"
						/>

						<feather-icon
							icon="ChevronRightIcon"
							size="40"
							class="text-primary cursor-pointer"
							style="margin-left: 10px"
							@click="nextWeek()"
						/>
					</div>
					<b-button
						class="position-relative text-nowrap mt-md-1"
						variant="primary"
						@click="
							;(incomingsModal.info = { season, isOwnChild: true }),
								(incomingsModal.show = !incomingsModal.show)
						"
					>
						Ver Todas
						<b-badge class="ml-05" variant="danger" v-if="G_TOURNAMENTS_COUNTERS.next">
							{{ G_TOURNAMENTS_COUNTERS.next }}
						</b-badge>
					</b-button>
					<b-button
						class="position-relative text-nowrap mt-md-1"
						variant="primary"
						@click="showPendingEvents = !showPendingEvents"
						:disabled="pendingCount == 0"
					>
						<feather-icon class="mr-05" icon="InfoIcon" />

						Fechas clasificadas
						<b-badge
							class="position-absolute"
							style="top: -0.5rem; right: -0.5rem"
							variant="danger"
							v-if="G_PENDING_COUNTER"
						>
							{{ G_PENDING_COUNTER }}
						</b-badge>
					</b-button>
					<div class="d-flex mt-50 mt-md-1">
						<b-button class="text-nowrap w-100" variant="primary" @click="filters.show = !filters.show">
							<feather-icon class="mr-05" icon="FilterIcon" />
							Filtros
						</b-button>
						<b-button
							class="btn-icon text-nowrap ml-50 ml-xl-1"
							variant="primary"
							@click="clearFilters"
							v-b-tooltip.hover.top="`Refrescar tabla`"
						>
							<feather-icon icon="RefreshCwIcon" />
						</b-button>
					</div>
				</b-col>
			</b-row>
			<b-table-simple sticky-header="60vh">
				<!-- key to update render after rows fetch to remove rare borders -->
				<b-thead class="text-center" :key="rows.length">
					<b-tr v-if="!ownChild">
						<b-th
							class="bg-transparent"
							style="border: none !important"
							:colspan="[1, 2].includes(tab) ? 7 : 8"
						></b-th>
						<b-th class="bg-info text-white" :colspan="tab == 0 ? 2 : 4">
							<p class="font-weight-bolder mb-0">Gallos</p>
						</b-th>
						<b-th style="border-left: 2px solid #fff" class="bg-info text-white" colspan="2">
							<p class="font-weight-bolder mb-0">Costos</p>
						</b-th>
					</b-tr>
					<b-tr>
						<b-th class="text-nowrap bg-head-event">
							Fecha
							<span class="ml-05 clickable" :class="[isMuted(0)]" @click="changeOrderBy(2)">
								<feather-icon icon="ChevronDownIcon" size="20" v-if="orderBy[0].desc" />
								<feather-icon icon="ChevronUpIcon" size="20" v-else />
							</span>
						</b-th>
						<b-th class="text-nowrap bg-head-event">
							Campeonato
							<span class="ml-05 clickable" :class="[isMuted(1)]" @click="changeOrderBy(3)">
								<feather-icon icon="ChevronDownIcon" size="20" v-if="orderBy[1].desc" />
								<feather-icon icon="ChevronUpIcon" size="20" v-else />
							</span>
						</b-th>
						<b-th class="text-nowrap bg-head-event">
							Coliseo
							<span class="ml-05 clickable" :class="[isMuted(2)]" @click="changeOrderBy(4)">
								<feather-icon icon="ChevronDownIcon" size="20" v-if="orderBy[2].desc" />
								<feather-icon icon="ChevronUpIcon" size="20" v-else />
							</span>
						</b-th>
						<b-th class="text-nowrap bg-head-event">
							Etapa
							<span class="ml-05 clickable" :class="[isMuted(3)]" @click="changeOrderBy(5)">
								<feather-icon icon="ChevronDownIcon" size="20" v-if="orderBy[3].desc" />
								<feather-icon icon="ChevronUpIcon" size="20" v-else />
							</span>
						</b-th>
						<b-th class="text-nowrap bg-head-event">
							Tipo
							<span class="ml-05 clickable" :class="[isMuted(4)]" @click="changeOrderBy(7)">
								<feather-icon icon="ChevronDownIcon" size="20" v-if="orderBy[4].desc" />
								<feather-icon icon="ChevronUpIcon" size="20" v-else />
							</span>
						</b-th>
						<b-th class="text-nowrap bg-head-event">
							Metodo
							<span class="ml-05 clickable" :class="[isMuted(5)]" @click="changeOrderBy(8)">
								<feather-icon icon="ChevronDownIcon" size="20" v-if="orderBy[5].desc" />
								<feather-icon icon="ChevronUpIcon" size="20" v-else />
							</span>
						</b-th>
						<b-th class="text-nowrap bg-head-event">Peso (KG)</b-th>
						<b-th class="bg-head-event">Frentes</b-th>
						<b-th class="bg-head-event">Requeridos</b-th>
						<b-th class="bg-head-event">Registrados</b-th>
						<b-th class="bg-head-event" v-if="[1, 2].includes(tab)">Jugaron</b-th>
						<b-th class="bg-head-event">Frente</b-th>
						<b-th class="bg-head-event">Total</b-th>
						<!-- <b-th class="bg-head-event">Responsable</b-th> -->
						<b-th class="bg-head-event" v-if="!ownChild">Colaboradores</b-th>
						<b-th class="bg-head-event" v-if="!ownChild">Registrar</b-th>
						<b-th class="bg-head-event" v-if="[0, 1].includes(tab) && !ownChild">Accion</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr :class="[rowColor(row)]" v-for="row in rows" :key="`event-${row.id}`">
						<b-td>
							<div>
								<p class="mb-0">{{ row.date | myGlobalDayShort }}</p>
								<feather-icon
									v-if="([0].includes(tab) && !ownChild) || forModal"
									@click="
										updateModal.show = true
										updateModal.info = row
									"
									icon="Edit2Icon"
									class="cursor-pointer"
								/>
							</div>
						</b-td>
						<b-td>
							<div style="display: flex; gap: 1rem; align-items: center; width: 19rem">
								<div>
									<ViewImage
										:specimen="{
											id: 1,
											url: row.url_image,
										}"
									/>
								</div>
								<span>{{ row.tournament }}</span>
							</div>
						</b-td>

						<b-td>
							{{ row.coliseum }}
							<br />
							<span style="font-size: 0.8rem">
								<b>{{ row.coliseum_city }}</b>
							</span>
						</b-td>
						<b-td class="text-nowrap">
							<!-- {{ row.counter_awards }} -->
							<b-badge :style="{ background: row.stage_color }">
								{{ row.stage }}
							</b-badge>
							<div
								class="d-flex align-items-center justify-content-center mt-1 cursor-pointer"
								v-if="row.position_id != null"
								@click="viewAwards(row)"
							>
								<b-badge :variant="row.position_id > 1 ? 'warning' : 'success'" class="ml-05">
									{{ row.position_name }}
								</b-badge>
								<v-icon
									v-if="row.position_id < 4"
									name="fa-trophy"
									scale="1.2"
									class="cursor-pointer ml-25"
									:style="{ color: colorizePosition(row.position_id) }"
								/>
							</div>
						</b-td>
						<b-td>{{ row.type }}</b-td>
						<b-td>
							<div style="display: flex; justify-content: center; gap: 0.2rem">
								<div>
									{{ row.method }}
								</div>
							</div>
							<div
								style="
									display: flex;
									align-items: center;
									justify-content: center;
									gap: 0.8rem;
									padding-top: 1rem;
								"
							>
								<i
									v-if="row.method === 'TAPADA'"
									v-b-tooltip.hover.top="'Esquema torneo'"
									class="fa-solid fa-diagram-project"
									@click="openModalDiagram(row)"
									:class="`${row.count_participant ? 'participant-count' : ''}`"
									style="color: #6e6b7b; cursor: pointer"
								></i>
								<div style="position: relative">
									<feather-icon
										v-if="row.method === 'TAPADA' && tab === 0"
										v-b-tooltip.hover.top="
											`${
												row.count_participant
													? `${row.count_participant} participantes`
													: 'Agregar participante'
											}`
										"
										icon="PlusIcon"
										size="20"
										style="color: #6e6b7b; cursor: pointer"
										:class="`${row.count_participant ? 'participant-count' : ''}`"
										@click="openModalParticipant(row)"
									/>
									<b-badge
										v-if="row.count_participant > 0"
										pill
										variant=""
										style="
											position: absolute;
											top: 0rem;
											background-color: #ea5455;
											color: white;
											top: -0.8rem;
										"
									>
										{{ row.count_participant }}
									</b-badge>
								</div>
							</div>
						</b-td>
						<b-td>
							{{ row.weight == "" || row.weight == null || row.weight == "null" ? "---" : row.weight }}
						</b-td>
						<b-td>
							<div class="d-flex align-items-center justify-content-center">
								<b-button
									class="btn-icon"
									variant="danger"
									size="sm"
									v-b-tooltip.hover.top="'Eliminar un frente'"
									@click="removeFront(row)"
									:disabled="!row.is_minus_front || row.fronts <= 1 || row.event_id !== null"
									v-if="tab == 0 && !ownChild"
								>
									<feather-icon icon="MinusIcon" />
								</b-button>

								<p class="mb-0 ml-1 mr-1 font-weight-bolder">{{ row.fronts }}</p>
								<b-button
									class="btn-icon"
									variant="info"
									size="sm"
									v-b-tooltip.hover.top="'Agregar un frente'"
									@click="addFront(row)"
									v-if="tab == 0 && !ownChild"
									:disabled="!!row.event_id"
								>
									<feather-icon icon="PlusIcon" />
								</b-button>
							</div>
						</b-td>
						<b-td>{{ row.specimens.required }}</b-td>
						<b-td>
							<span
								:class="[1, 2].includes(tab) ? 'text-primary font-weight-bolder cursor-pointer' : ''"
								@click=";[1, 2].includes(tab) ? openRegisteredSpecimens(row) : null"
							>
								{{ row.specimens.registered }}
							</span>
							<feather-icon
								class="ml-05 clickable"
								icon="Edit3Icon"
								size="20"
								@click=";(manageFront.show = !manageFront.show), (manageFront.info = row)"
								v-if="[0].includes(tab) && !ownChild"
							/>
						</b-td>
						<b-td v-if="[1, 2].includes(tab)">
							<span
								:class="[1, 2].includes(tab) ? 'text-primary font-weight-bolder cursor-pointer' : ''"
								@click="openSpecimensPlayed(row)"
							>
								{{ row.specimens.played }}
							</span>
						</b-td>

						<b-td class="text-nowrap">
							<div class="d-flex align-items-center justify-content-between">
								<p class="mb-0">
									<!-- {{
										Number(row.price_stage).toLocaleString("es-PE", {
											style: "currency",
											currency: "PEN",
										})
									}}xxx	 -->

									{{ row.currency.name }} {{ row.price_stage }}
								</p>
							</div>
						</b-td>

						<b-td class="text-nowrap">
							<div class="d-flex align-items-center justify-content-between">
								<p class="mb-0">
									<!-- {{
										Number(row.amount).toLocaleString("es-PE", {
											style: "currency",
											currency: "PEN",
										})
									}} -->
									{{ row.currency.name }} {{ row.amount }}
								</p>
							</div>
						</b-td>

						<b-td>
							<!-- <div
								v-if="row.responsible"
								class="d-flex justify-content-between align-items-center gap-x-1"
							>
								<small>R.</small>
								<span>{{ row.responsible }}</span>
							</div> -->
							<div
								class="d-flex flex-column align-items-center justify-content-center gap-x-1 mt-1"
								v-if="!ownChild"
							>
								<div class="w-100">
									<div
										class="d-flex justify-content-between gap-x-1 mb-05 w-100 cursor-pointer"
										v-for="role in row.collaborators"
										:key="role.role"
									>
										<small>{{ role.role }}.</small>
										<span class="text-nowrap">{{ role.user }}</span>
									</div>
								</div>
								<feather-icon
									class="flex-grow text-dark ml-05 clickable"
									icon="Edit3Icon"
									size="20"
									@click="
										;(editCollaborators.show = !editCollaborators.show),
											(editCollaborators.info = row)
									"
									v-if="[0].includes(tab)"
								/>
							</div>
						</b-td>
						<b-td v-if="!ownChild">
							<b-button
								class="position-relative w-100 mb-1"
								:variant="
									parseFloat(row.fronts_percentage) == 0
										? 'secondary'
										: parseFloat(row.fronts_percentage) == 100
										? 'success'
										: parseFloat(row.fronts_percentage) > 0
										? 'warning'
										: 'secondary'
								"
								size="sm"
								:disabled="row.specimens.registered !== null && row.specimens.registered < 1"
								@click="handleResultsClick(row)"
							>
								<span style="text-decoration: underline">Resultados</span>
							</b-button>

							<b-button
								class="position-relative w-100 mb-1"
								size="sm"
								@click=";(frontPayments.show = !frontPayments.show), (frontPayments.info = row)"
								:variant="
									row.pay_status == 1 ? 'secondary' : row.pay_status == 2 ? 'warning' : 'success'
								"
							>
								<span style="text-decoration: underline">Pagos</span>
							</b-button>

							<b-button
								class="position-relative w-100"
								:variant="
									row.count_per_diem && row.per_diem_status_id == 1
										? 'warning'
										: row.count_per_diem && row.per_diem_status_id == 2
										? 'success'
										: 'secondary'
								"
								size="sm"
								@click="
									expenses.show = !expenses.show
									expenses.info = row
								"
							>
								<span style="text-decoration: underline">Viaticos</span>
							</b-button>
						</b-td>
						<b-td v-if="[0].includes(tab) && !ownChild">
							<div class="d-flex gap-x-2">
								<template v-if="[0, 1].includes(tab)">
									<b-button
										class="btn-icon"
										variant="primary"
										size="sm"
										v-if="row.counter_awards != null"
										v-b-tooltip.hover.top="'Premios'"
										@click="viewAwards(row, true)"
									>
										<feather-icon icon="AwardIcon" />
									</b-button>

									<b-button class="btn-icon" size="sm" v-else disabled>
										<feather-icon icon="AwardIcon" />
									</b-button>
								</template>
								<b-button
									class="btn-icon"
									variant="success"
									size="sm"
									@click="updateStatus(row, 2)"
									v-if="tab == 0 && canSendtoPlayed(row)"
								>
									<feather-icon icon="CheckIcon" />
								</b-button>
								<b-button disabled class="btn-icon" size="sm" v-else>
									<feather-icon icon="CheckIcon" />
								</b-button>
								<b-button class="btn-icon" variant="danger" size="sm" @click="updateStatus(row, 3)">
									<feather-icon icon="XIcon" />
								</b-button>
							</div>
						</b-td>
						<b-td v-if="[1].includes(tab)">
							<b-button
								class="btn-icon"
								variant="primary"
								size="sm"
								v-if="row.counter_awards != null"
								v-b-tooltip.hover.top="'Premios'"
								@click="viewAwards(row, true)"
							>
								<feather-icon icon="AwardIcon" />
							</b-button>

							<b-button class="btn-icon" size="sm" v-else disabled>
								<feather-icon icon="AwardIcon" />
							</b-button>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length != 0">
						<b-td class="bg-transparent" colspan="7"></b-td>
						<b-td class="font-weight-bolder" variant="light">Total:</b-td>
						<b-td class="font-weight-bolder" variant="primary">{{ rows[0].totals.required }}</b-td>
						<b-td class="font-weight-bolder" variant="primary">{{ rows[0].totals.registered }}</b-td>
						<b-td class="font-weight-bolder" variant="primary" v-if="tab == 1">
							{{ rows[0].totals.played }}
						</b-td>
						<b-td></b-td>
						<b-td class="registeredBg font-weight-bolder">
							{{
								Number(rows[0].totals.amount).toLocaleString("es-PE", {
									style: "currency",
									currency: "PEN",
								})
							}}
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="10">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<b-sidebar backdrop v-model="filters.show" body-class="bg-white">
			<template #title>
				<h2 class="pt-05">
					<feather-icon class="mr-05 text-primary" icon="FilterIcon" size="25" />
					Filtros
				</h2>
			</template>
			<div class="p-1" style="overflow: hidden">
				<b-form-group label="Torneo">
					<v-select
						class="border-primary rounded"
						label="name"
						:options="filters.tournamentOpts"
						:reduce="(opt) => opt.id"
						:clearable="false"
						v-model="filters.tournament"
						placeholder="Seleccionar torneo"
					/>
				</b-form-group>
				<b-form-group label="Etapa">
					<v-select
						class="border-primary rounded"
						label="name"
						:options="filters.stageOpts"
						:reduce="(opt) => opt.id"
						:clearable="false"
						v-model="filters.stage"
						placeholder="Seleccionar etapa"
					/>
				</b-form-group>
				<b-form-group label="Coliseo">
					<v-select
						class="border-primary rounded"
						label="name"
						:options="filters.coliseumOpts"
						:reduce="(opt) => opt.id"
						:clearable="false"
						v-model="filters.coliseum"
						placeholder="Seleccionar coliseo"
					/>
				</b-form-group>
				<b-form-group label="Tipo">
					<v-select
						class="border-primary rounded"
						label="name"
						:options="filters.typeOpts"
						:reduce="(opt) => opt.id"
						:clearable="false"
						v-model="filters.type"
						placeholder="Seleccionar tipo"
					/>
				</b-form-group>
				<b-form-group label="Metodo">
					<v-select
						class="border-primary rounded"
						label="name"
						:options="filters.methodOpts"
						:reduce="(opt) => opt.id"
						:clearable="false"
						v-model="filters.method"
						placeholder="Seleccionar metodo"
					/>
				</b-form-group>
				<b-row>
					<b-col cols="6">
						<b-form-group label="Desde">
							<b-form-datepicker
								class="border-primary"
								locale="en-US"
								placeholder="Inicio"
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="filters.start"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label="Hasta">
							<b-form-datepicker
								class="border-primary"
								locale="en-US"
								placeholder="Fin"
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="filters.end"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-form-group class="pt-3">
					<template #label>
						<div class="d-flex align-items-center">
							Costos
							<b-form-checkbox class="ml-1" switch inline v-model="filters.expensesActive" />
						</div>
					</template>
					<veeno
						class="px-2"
						connect
						:tooltips="[true, true]"
						:range="{ min: 0, max: filters.expensesMax }"
						:step="1"
						:handles="filters.expenses"
						:disabled="!filters.expensesActive"
						:key="filters.expensesMax"
						@change="handleSliderAmount"
					/>
				</b-form-group>
			</div>
			<template #footer>
				<div class="d-flex justify-content-end p-1" style="gap: 1rem">
					<b-button @click="clearFilters">Limpiar</b-button>
					<b-button variant="primary" @click=";(filters.show = false), getRows()">Buscar</b-button>
				</div>
			</template>
		</b-sidebar>
		<ManageFrontModal
			:show="manageFront.show"
			:info="manageFront.info"
			@closing="manageFront.show = !manageFront.show"
			@refresh="getRows"
		/>
		<ExpensesModal
			:tab="tab"
			@refresh="getRows"
			:show="expenses.show"
			:info="expenses.info"
			@closing="expenses.show = !expenses.show"
		/>
		<ResultsModal
			:tab="tab"
			@refresh="getRows"
			:show="results.show"
			:info="results.info"
			@closing="results.show = !results.show"
		/>
		<EditCollaboratorsModal
			:show="editCollaborators.show"
			:info="editCollaborators.info"
			@closing="editCollaborators.show = false"
			@refresh="getRows"
		/>
		<TrackingEventSpecimens
			:show="trackingSpecimens.show"
			:info="trackingSpecimens.info"
			@closing="trackingSpecimens.show = !trackingSpecimens.show"
		/>
		<PendingEventsModal
			:show="showPendingEvents"
			@closing="showPendingEvents = false"
			@refresh="getRows"
			:season="season"
		/>
		<MassiveEventsModal
			:show="createEvent.show"
			@closing="createEvent.show = false"
			@refresh="getRows"
			:disabled_price_zero="false"
			:season="season"
		/>
		<AwardsListModal
			:show="awardsModal.show"
			:info="awardsModal.info"
			@closing="awardsModal.show = false"
			@refresh="getRows"
			:all="awardsModal.all"
		/>
		<FrontPaymentsModal
			:show="frontPayments.show"
			:info="frontPayments.info"
			@closing="frontPayments.show = false"
			@refresh="getRows"
		/>

		<UpdateDateEvent
			:show="updateModal.show"
			:info="updateModal.info"
			@closing="updateModal.show = false"
			@refresh="getRows"
		/>
		<IncomingEventsModal
			:show="incomingsModal.show"
			:info="incomingsModal.info"
			@closing="incomingsModal.show = false"
		/>

		<ModalDiagramTournament v-if="showDiagram" @close="showDiagram = false" :infoDiagram="infoDiagram" :tab="tab" />
		<AddParticipantTournament
			v-if="showAddTournament"
			@close="showAddTournament = false"
			:infoParticipant="infoParticipant"
			@refresh="getRows(), (showAddTournament = false)"
		/>
	</div>
</template>

<script>
import moment from "moment"
import tournamentService from "@/services/tournament/default.service"
import coliseumService from "@/services/tournament/coliseum.service"
import tournamentStagesService from "@/services/tournament/stages.service"
import { mapGetters, mapActions } from "vuex"
import veeno from "veeno"
import "nouislider/distribute/nouislider.min.css"
import ManageFrontModal from "../components/ManageFrontModal.vue"
import ExpensesModal from "../components/ExpensesModal.vue"
import ResultsModal from "../components/ResultsModal.vue"
import EditCollaboratorsModal from "../components/EditCollaboratorsModal.vue"
import TrackingEventSpecimens from "../components/TrackingEventSpecimens.vue"
import PendingEventsModal from "../components/PendingEventsModal.vue"
import MassiveEventsModal from "../components/MassiveEventsModal.vue"
import AwardsListModal from "../components/AwardsListModal.vue"
import useCalendar from "@/views/amg/tournaments/v2/components/calendar/useCalendar.js"
import FrontPaymentsModal from "../components/FrontPaymentsModal.vue"
import UpdateDateEvent from "../components/UpdateDateEvent.vue"
import IncomingEventsModal from "@/views/amg/preparacion/components/IncomingEventsModal.vue"
import ViewImage from "@/components/commons/ViewImage.vue"
import ModalDiagramTournament from "@/views/amg/tournaments/v2/components/ModalDiagramTournament.vue"
import AddParticipantTournament from "@/views/amg/tournaments/v2/components/AddParticipantTournament.vue"
export default {
	name: "Events",
	components: {
		ManageFrontModal,
		ExpensesModal,
		ResultsModal,
		EditCollaboratorsModal,
		veeno,
		TrackingEventSpecimens,
		PendingEventsModal,
		MassiveEventsModal,
		AwardsListModal,
		FrontPaymentsModal,
		UpdateDateEvent,
		IncomingEventsModal,
		ViewImage,
		ModalDiagramTournament,
		AddParticipantTournament,
	},
	props: { season: Object, triggerAddEvent: Number, ownChild: Boolean, forModal: Boolean },
	data: () => ({
		tab: 0,
		isLoading: false,
		showDiagram: false,
		orderBy: [
			{ col: 2, desc: false, active: true },
			{ col: 3, desc: false, active: false },
			{ col: 4, desc: false, active: false },
			{ col: 5, desc: false, active: false },
			{ col: 7, desc: false, active: false },
			{ col: 8, desc: false, active: false },
		],
		filters: {
			show: false,
			tournament: null,
			stage: null,
			coliseum: null,
			type: null,
			method: null,
			start: null,
			end: null,
			expenses: [0, 1],
			expensesMax: 1,
			expensesActive: false,
			tournamentOpts: [],
			coliseumOpts: [],
			stageOpts: [],
			typeOpts: [],
			methodOpts: [
				{ name: "PESO", id: 1 },
				{ name: "TAPADA", id: 2 },
			],
		},
		rows: [],
		counters: { actual: 0, next: 0, played: 0, canceled: 0, week: 0 },
		collaboratorOpts: [], // borrar
		editingResponsible: null, // borrar
		createEvent: { show: false },
		manageFront: { show: false, info: {} },
		expenses: { show: false, info: {} },
		results: { show: false, info: {} },
		editCollaborators: { show: false, info: {} },
		trackingSpecimens: { show: false, info: {} },
		showPendingEvents: false,
		pendingCount: null,
		massiveEvents: { show: false, info: {} },
		awardsModal: { show: false, info: {}, all: false },
		frontPayments: { show: false, info: {} },
		counterCalendar: 0,
		updateModal: { show: false, info: {}, all: false },
		weekFrom: null,
		weekTo: null,
		incomingsModal: { show: false, info: {} },
		dateClasification: { show: false, info: {} },
		showAddTournament: false,
		infoParticipant: {},
		infoDiagram: {},
	}),
	computed: {
		curOrder() {
			let col = this.orderBy.find((o) => o.active)
			if (!col) return undefined
			return col
		},
		...mapGetters({
			G_PENDING_COUNTER: "counters/G_PENDING_COUNTER",
			G_TOURNAMENTS_COUNTERS: "counters/G_TOURNAMENTS_COUNTERS",
		}),
	},
	methods: {
		...mapActions({
			A_PENDING_EVENTS_COUNTER: "counters/A_PENDING_EVENTS_COUNTER",
			A_TOURNAMENTS_COUNTERS: "counters/A_TOURNAMENTS_COUNTERS",
		}),
		changeOrderBy(col) {
			this.orderBy.forEach((order) => {
				if (order.col == col) {
					order.active = true
					order.desc = !order.desc
				} else {
					order.desc = false
					order.active = false
				}
			})
			this.getRows()
		},

		openModalDiagram(info) {
			this.infoDiagram = info
			this.showDiagram = true
		},
		openModalParticipant(data) {
			this.infoParticipant = {
				idEvent: data.id,
				date: data.date,
				name: data.tournament,
			}
			this.showAddTournament = true
		},
		handleResultsClick(row) {
			this.results.info = row
			this.results.show = !this.results.show
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tournamentService.getEvents({
				start_temporary_date: this.season.start,
				end_temporary_date: this.season.end,
				order: this.curOrder.desc ? "desc" : "asc",
				orderBy: this.curOrder.col,
				coliseum_id: this.filters.coliseum,
				method_id: this.filters.method,
				p_stage_id: this.filters.stage,
				p_type_id: this.filters.type,
				tournament_id: this.filters.tournament,
				start_date: this.filters.start,
				end_date: this.filters.end,
				status_id: this.ownChild ? 5 : this.tab + 1,
				min_amount: this.filters.expensesActive ? this.filters.expenses[0] : null,
				max_amount: this.filters.expensesActive ? this.filters.expenses[1] : null,
				week_from: this.weekFrom,
				week_to: this.weekTo,
			})
			this.rows = data.data
			this.getCounterCalendar()
			this.isLoading = false
			this.$emit("refresh")
			if (this.forModal) {
				return
			} else {
				await this.getCounters()
			}
		},

		nextWeek() {
			const newFrom = new Date(this.weekFrom)
			const newTo = new Date(this.weekTo)
			newFrom.setDate(newFrom.getDate() + 8)
			newTo.setDate(newTo.getDate() + 8)
			this.weekFrom = moment(newFrom).format("YYYY-MM-DD")
			this.weekTo = moment(newTo).format("YYYY-MM-DD")
			this.getRows()
			this.getCounters()
		},

		previousWeek() {
			const newFrom = new Date(this.weekFrom)
			const newTo = new Date(this.weekTo)
			newFrom.setDate(newFrom.getDate() - 6)
			newTo.setDate(newTo.getDate() - 6)
			this.weekFrom = moment(newFrom).format("YYYY-MM-DD")
			this.weekTo = moment(newTo).format("YYYY-MM-DD")
			this.getRows()
			this.getCounters()
		},

		async getFilterOpts() {
			const req = [
				tournamentService.getTournaments({
					start_date: this.season.start,
					end_date: this.season.end,
				}),
				coliseumService.getColiseumsToSelect({
					campo: null,
					pageIn: 1,
					perPageIn: 1000,
					status: 0,
				}),
				tournamentService.getTournamentTypes(),
				tournamentStagesService.index(),
				tournamentService.getAmountRange(),
			]

			const data = await Promise.all(req)

			this.filters.tournamentOpts = data[0].data.data
			this.filters.coliseumOpts = data[1].data.data.map((c) => ({
				name: c.name,
				id: c.id_coliseum,
			}))
			this.filters.typeOpts = data[2].data.data
			this.filters.stageOpts = data[3].data.data
			this.filters.expensesMax = data[4].data[0].amount || 1
			this.filters.expenses[1] = this.filters.expensesMax

			// borrar
			const { data: collaborators } = await tournamentService.getCollaborators()
			this.collaboratorOpts = collaborators.data
		},
		clearFilters() {
			this.filters = {
				...this.filters,
				show: false,
				tournament: null,
				stage: null,
				coliseum: null,
				type: null,
				method: null,
				start: null,
				end: null,
				expenses: [1, 2],
				expensesActive: false,
			}
			this.getRows()
		},
		handleSliderAmount(e) {
			this.filters.expenses = [Number(e.values[0]), Number(e.values[1])]
		},
		rowColor(row) {
			if (row.past) {
				return "table-danger"
			} else if (row.is_current_date) {
				return "table-success"
			} else {
				return "row-body-table"
			}
		},
		isMuted(idx) {
			if (this.orderBy[idx].active) return null
			return "text-muted"
		},
		async updateResponsible(e) {
			// borrar
			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Seguro de actualizar el responsable del evento?`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			await tournamentService.updateResponsible({
				event_id: this.editingResponsible,
				responsible_id: e,
			})
			this.editingResponsible = null
			this.isLoading = false
			this.getRows()
		},
		async updateStatus(row, statusRow) {
			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se pasara el evento ${row.tournament} al estado ${statusRow == 2 ? "JUGADO" : "CANCELADO"}`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			const { status, message } = await tournamentService.updateEventStatus({
				event_id: row.id,
				status: statusRow,
			})
			if (status) {
				this.showToast("success", "top-right", "Torneos", "SuccessIcon", message)
			}
			this.isLoading = false
			this.counterTrigger = Symbol()
			this.getRows()
		},
		openRegisteredSpecimens(row) {
			this.trackingSpecimens.show = !this.trackingSpecimens.show
			this.trackingSpecimens.info = { ...row, _status: "registered" }
		},
		openSpecimensPlayed(row) {
			this.trackingSpecimens.show = !this.trackingSpecimens.show
			this.trackingSpecimens.info = { ...row, _status: "qualified" }
		},
		canSendtoPlayed(row) {
			return (
				parseFloat(row.fronts_percentage) == 100 &&
				// row.count_per_diem > 0 &&
				// row.per_diem_status_id == 2 &&
				row.collaborators &&
				row.collaborators.length == 4
			)
		},
		colorizePosition(position) {
			switch (position) {
				case 1:
					return "#FFD700"
				case 2:
					return "#C0C0C0"
				case 3:
					return "#CD7F32"
				default:
					return "gray"
			}
		},
		viewAwards(row, all = false) {
			if ((row.position_counter > 0 && !all) || (row.counter_awards > 0 && all)) {
				this.awardsModal.show = !this.awardsModal.show
				this.awardsModal.info = row
				this.awardsModal.all = all
			} else {
				this.showToast("info", "top-right", "Torneos", "InfoIcon", "No hay premios asignados")
			}
		},
		async getCounterCalendar() {
			const {
				data: { counter },
			} = await tournamentService.counterCalendar()

			this.counterCalendar = counter
		},
		async addFront(row) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Seguro de desea agregar un frente a ${row.tournament} / ${row.stage} / ${row.date}?`,
			})
			if (!isConfirmed) return
			try {
				this.isLoading = true
				await tournamentService.addFront({ event_id: row.id })
				this.showToast("success", "top-right", "Torneos", "InfoIcon", "Se agregó un frente de forma correcta")
				this.isLoading = false
				this.getRows()
			} catch (error) {
				this.isLoading = false
			}
		},
		async removeFront(row) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Seguro de desea agregar un frente a ${row.tournament} / ${row.stage} / ${row.date}?`,
			})
			if (!isConfirmed) return
			try {
				this.isLoading = true
				await tournamentService.removeFront({ event_id: row.id })
				this.showToast("success", "top-right", "Torneos", "InfoIcon", "Se eliminó un frente de forma correcta")
				this.isLoading = false
				this.getRows()
			} catch (error) {
				this.isLoading = false
			}
		},

		async generateWeekDates() {
			const currentDate = new Date()
			const weekDays = currentDate.getDay() // Retorna un número del 0 al 6, donde 0 es domingo.

			// Calcular la diferencia entre el día actual y el lunes
			const differenceBetweenDays = weekDays === 0 ? -6 : 1 - weekDays
			const mondayDate = new Date(currentDate)
			mondayDate.setDate(currentDate.getDate() + differenceBetweenDays)

			// Calcular la diferencia entre el día actual y el domingo
			const diasDiferenciaDomingo = weekDays === 0 ? 0 : 7 - weekDays
			const sundayDate = new Date(currentDate)
			sundayDate.setDate(currentDate.getDate() + diasDiferenciaDomingo)

			this.weekFrom = moment(mondayDate).format("YYYY-MM-DD")
			this.weekTo = moment(sundayDate).format("YYYY-MM-DD")
		},

		async getCounters() {
			await this.A_PENDING_EVENTS_COUNTER()
			await this.A_TOURNAMENTS_COUNTERS({
				start: this.season.start,
				end: this.season.end,
				weekFrom: this.weekFrom,
				weekTo: this.weekTo,
			})
		},
	},
	created() {
		if (this.tab + 1 == 1) {
			this.generateWeekDates()
		}
		this.getRows()
		this.getFilterOpts()
	},
	watch: {
		triggerAddEvent(val) {
			this.createEvent.show = !this.createEvent.show
		},
		season(val) {
			this.getRows()
		},
	},
	setup() {
		const { counterTrigger } = useCalendar()
		return { counterTrigger }
	},
}
</script>

<style lang="scss" scoped>
.registeredBg {
	background-color: rgba($color: #7367f0, $alpha: 0.5);
}

[dir] [disabled] .noUi-connect {
	background: #b8b8b8;
}
[dir] .noUi-connect {
	background: #7367f0;
}

.row-body-table {
	td {
		background: #fff;
	}
}
.row-body-table-danger {
	td {
		background: rgba(249, 174, 174, 0.5);
	}
}
[dir] .table thead th {
	border-bottom: none !important;
}
.bg-head-event {
	background: #efeeee !important;
}
.participant-count {
	color: #7367f0 !important;
	font-weight: 900;
}
.badge {
	padding: 0.3rem 0.5rem;
	border-radius: 0.358rem;
}
</style>
